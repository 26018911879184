import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.51.1_react-d_ba0907e7fcfbcdebd0976413bf79d054/node_modules/next/dist/client/components/render-from-template-context.js");
